import request from '@/../utils/request'
const api_name = '/iotechserver/loginUserJuris'

export default {


    // // 设置平台名称
    // setShows(platformName, platformSlogan, showFlag, picture, pictureFlag, userName) {
    setShows(formData) {
        return request({
            url: `${api_name}/setShows`,
            method: 'post',
            data: formData
        })
    },

    // // 查询相关属性
    getShowsByUserName(userName) {
        return request({
            url: `${api_name}/getShowsByUserName/${userName}`,
            method: 'get',
        })
    },

}