<template>
  <div id="app">
    <!-- <el-row style="margin:10px 0">
      <el-col :span="24">
        <el-page-header @back="goSelect" content="首页CRT点位管理"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-row :gutter="0" style="margin:10px">
      <el-col :span="24" :offset="0">
        <div id="overburden">
          <el-popover placement="right-start" trigger="click" @show="popoverShow = true" @hide="popoverHide">
            <div class="supportDiv">
              <el-row :gutter="20" class="supportDivRow1">
                <el-col :span="24" :offset="0">
                  <el-table :data="crtInfoList" highlight-current-row @current-change="handleCurrentChange">
                    <el-table-column prop="name" label="CRT名称"></el-table-column>
                  </el-table>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="24" :offset="0">
                  <span>图标尺寸(单位：px)</span>
                  <el-slider :min="5" :max="200" v-model="indexCrtDeviceCoordinateInfo.iconWidth"></el-slider>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="14" :offset="10">
                  <el-button size="default" @click="closePopover">取消</el-button>
                  <el-button type="primary" size="default" @click="realAddCoordinate">添加</el-button>
                </el-col>
              </el-row>
            </div>

            <div id="showListForCheck" slot="reference">
              <i class="el-icon-location"></i>
            </div>
          </el-popover>
          <!--前端设备点位-->
          <img v-for="item in indexPageCrtPointList" :id="item.crtPictureId" src="../../assets/images/CRT点位.png" :style="{'left':(parseFloat(item.coordinatex)*showBgWid-parseFloat(item.iconWidth)/2).toString()+'px','top':(parseFloat(item.coordinatey)*showBgHei-parseFloat(item.iconWidth)/2).toString()+'px','width':item.iconWidth+'px','position':'absolute','z-index':'2','cursor':'pointer'}" @click="remove(item.id)"></img>

          <!-- 正式平台首页背景图 -->
          <img id="showBg" :src="imgUrl" @click="addCoordinate"></img>

        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import AccountManage from '@/api/manageApi/AccountManage'
import User from '@/api/manageApi/User'

import crt from "@/api/manageApi/CrtInfo";
import indexPageCrtPoint from "@/api/manageApi/IndexPageCrtPoint";
import BaseData from "@/assets/config/BaseData.js";

export default {
  data() {
    return {
      //已添加点位列表
      indexPageCrtPointList: [],
      //未添加点位的crt信息列表
      crtInfoList: [],
      //当前选中的crt信息
      indexCrtDeviceCoordinateInfo: {
        id: "",
        crtPictureId: "",
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 20,
      },

      //设备列表当前选中
      currentRow: null,
      //添加点位弹框展示状态
      popoverShow: false,
      //当前图的宽度
      showBgWid: 0,
      //当前图的高度
      showBgHei: 0,

      imgUrl: '',
      platformInfo: [],
    };
  },
  created() {
    this.getUserInfo()
    this.getNoCoordinateCrtInfoList();
  },
  mounted() {
    var _this = this;

    setTimeout(function () {
      var showBg = document.getElementById("showBg");
      _this.showBgWid = showBg.width;
      _this.showBgHei = showBg.height;

      _this.getIndexPageCrtPointList();
    }, 1000);
  },
  watch: {
    //监听弹框是否展示   若果弹框展示图标则展示  弹框不展示图标隐藏
    popoverShow(newVal, oldVal) {
      if (newVal) {
        document.getElementById("showListForCheck").style.opacity = 1;
      } else {
        document.getElementById("showListForCheck").style.opacity = 0;
      }
    },
  },
  methods: {
    goSelect() {
      this.$router.push("/Select");
    },
    /* =========================数据操作============================== */
    //获取已添加的点位信息
    getIndexPageCrtPointList() {
      indexPageCrtPoint.list().then((res) => {
        this.indexPageCrtPointList = res.data.data.list;
      });
    },
    //获取全部未添加的crt图片点位
    getNoCoordinateCrtInfoList() {
      crt.getNoCoordinateCrtInfoList().then((res) => {
        this.crtInfoList = res.data.data.list;
        console.log(this.crtInfoList);
      });
    },
    /* =========================点位相关操作============================== */
    //点击点位跳转至crt页面
    remove(id) {
      this.$confirm("此操作将永久删除该点位, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        indexPageCrtPoint.remove(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getIndexPageCrtPointList();
          this.getNoCoordinateCrtInfoList();
          this.clearIndexCrtDeviceCoordinateInfo();
        });
      });
    },
    //  toCRT(crtPictureId) {
    //     this.$router.push({
    //         path: "/CRTShow",
    //         query: {
    //             CRTId: crtPictureId,
    //             deviceId: crtPictureId,
    //         }
    //     });
    // },
    //弹框隐藏是触发
    popoverHide() {
      this.popoverShow = false;
      this.currentRow = null;
    },
    //设备信息列表当前选中改变时触发
    handleCurrentChange(val) {
      this.currentRow = val;
      if (val) {
        this.indexCrtDeviceCoordinateInfo.crtPictureId = this.currentRow.id;
      }
    },
    //取消
    closePopover() {
      document.getElementById("app").click();
    },
    //添加点位
    addCoordinate(e) {
      e = e || window.event;
      var xx = e.offsetX,
        yy = e.offsetY;

      var im = document.getElementById("showBg");
      this.indexCrtDeviceCoordinateInfo.coordinatex = (xx - 7) / im.width;
      this.indexCrtDeviceCoordinateInfo.coordinatey = (yy - 13) / im.height;
      setTimeout(function () {
        let a = document.getElementById("showListForCheck");
        a.click();
      }, 100);
      document.getElementById("showListForCheck").style.left = xx - 7 + "px";
      document.getElementById("showListForCheck").style.top = yy - 13 + "px";
    },
    //确认添加点位
    realAddCoordinate() {
      //校验是否选择设备
      if (!this.indexCrtDeviceCoordinateInfo.crtPictureId) {
        //提示信息
        this.$message({
          type: "warning",
          message: "请选择设备！",
        });
      } else {
        //添加绑定关系
        indexPageCrtPoint.add(this.indexCrtDeviceCoordinateInfo).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //刷新
          if (res.data.success) {
            this.closePopover();
            this.getNoCoordinateCrtInfoList();
            this.getIndexPageCrtPointList();
            this.clearIndexCrtDeviceCoordinateInfo();
          }
        });
      }
    },
    clearIndexCrtDeviceCoordinateInfo() {
      this.indexCrtDeviceCoordinateInfo = {
        id: "",
        crtPictureId: "",
        coordinatex: 0,
        coordinatey: 0,
        iconWidth: 10,
      };
    },


    async getUserInfo() {
      const { data: res } = await User.loginUserInfo()
      this.users = res.data.userName
      console.log(this.users);
      this.getTitleText()
    },

    getTitleText() {
      AccountManage.getShowsByUserName(this.users).then(res => {
        this.platformInfo = res.data.data.data
        this.imgUrl = BaseData.baseUrl + this.platformInfo.picture
        console.log(this.imgUrl + this.platformInfo.picture);
      })
    },



  },
};
</script>

<style scoped>
#showBg {
  width: 1300px;
  position: relative;
}

#overburden {
  position: absolute;
}

#showListForCheck {
  opacity: 0;
  position: absolute;
  color: red;
  z-index: 2;
}

.supportDiv {
  width: 300px;
}

.supportDivRow1 {
  margin-bottom: 20px;
}
</style>
