// 状态历史表api
import request from '../../../utils/request'
const api_name = '/iotechserver/indexPageCrtPoint'

export default {
  list() { //处理修改状态，并增加处理类型和说明
    return request({
      url: `${api_name}/list`,
      method: 'get'
    })
  },
  add(indexPageCrtPoint) { //处理修改状态，并增加处理类型和说明
    return request({
      url: `${api_name}/add`,
      method: 'post',
      data:indexPageCrtPoint
    })
  },
  remove(id) { //处理修改状态，并增加处理类型和说明
    return request({
      url: `${api_name}/remove/${id}`,
      method: 'delete'
    })
  },
}